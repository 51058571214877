<template>
  <b-modal
    id="new-medical-insurance-modal"
    hide-header
    hide-footer
    centered
    @hidden="resetModal"
    @show="onShow"
    body-class="p-0"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title" v-if="editIndex !== null">Editar convênio</p>
      <p class="title" v-else>Adicionar convênio</p>
      <Close
        id="close"
        @click="$bvModal.hide('new-medical-insurance-modal')"
      />
    </div>
    <div class="body d-flex flex-column flex-fill">
      <b-row>
        <b-col cols="12">
          <div class="form-group">
            <label for="healthPlan">Nome do convênio</label>
            <multiselect
              id="healthPlan"
              v-model="form.health_plan"
              :options="healthPlans"
              track-by="id"
              label="fantasy_name"
              :showLabels="false"
              :internal-search="true"
              placeholder="Selecione o convênio"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Pesquise pelo nome ou código do convênio</template
              >
              <template slot="noResult"
                >Nenhum convênio encontrado</template
              >

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            <div v-if="validated && !form.health_plan" class="custom-invalid-feedback">Campo obrigatório</div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="form.health_plan && !form.health_plan.is_sus_health_plan">
        <b-col cols="12">
          <div class="form-group">
            <label for="plan">Plano <span class="help">(opcional)</span></label>
            <multiselect
              id="plan"
              v-model="form.plan"
              :options="plans"
              track-by="value"
              label="name"
              :showLabels="false"
              :internal-search="false"
              @search-change="debounceSearchPlan"
              placeholder="Selecione o plano"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"
                >Pesquise pelo nome ou código do plano</template
              >
              <template slot="noResult">Nenhum plano encontrado</template>

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="form.health_plan && !form.health_plan.is_sus_health_plan">
        <b-col cols="12">
          <div class="form-group">
            <label for="registration">Matrícula <span class="help" v-if="form.health_plan.type === 'particular'">(opcional)</span></label>
            <input
              autocomplete="off"
              id="registration"
              v-model="form.registration"
              type="text"
              class="form-control"
            />
            <div v-if="validated && (form.health_plan && form.health_plan.type === 'private_health') && !form.registration" class="custom-invalid-feedback">Campo obrigatório</div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="token">Token <span class="help">(opcional)</span></label>
            <input
              autocomplete="off"
              id="token"
              v-model="form.token"
              type="text"
              class="form-control"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="validUntil">
              Validade
              <span v-if="form.health_plan && form.health_plan.type !== 'private_health'" class="help">(opcional)</span>
            </label>
            <date-picker
                class="full"
                id="validUntil"
                v-model="form.valid_until"
                format="DD/MM/YYYY"
								placeholder="DD/MM/AAAA"
                :clearable="false"
                :lang="langDatePicker"
                autocomplete="off"
                v-mask="['##/##/####']" 
                :disabled-date="disabledDates"
              />
            <div v-if="validated && (form.health_plan && form.health_plan.type === 'private_health') && !form.valid_until" class="custom-invalid-feedback">Campo obrigatório</div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="form-group">
            <label for="holder">Titular <span class="help">(opcional)</span></label>
            <input
              autocomplete="off"
              id="holder"
              v-model="form.holder"
              type="text"
              class="form-control"
            />
          </div>
        </b-col>
      </b-row>
      <div class="mt-2 text-right">
         <b-button
          v-if="editIndex !== null"
          variant="primary"
          @click="editPlan"
        >
          Editar
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="add"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from 'moment'
import { debounce } from 'lodash';
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
export default {
  props: {
    personHealthPlan: Object,
    addPersonHealthPlan: Function,
    editPersonHealthPlan: Function,
    editIndex: Number
  },
  components: {
    Close,
    ChevronDown,
  },
  created() {
    this.debounceSearchPlan = debounce(this.searchPlan, 500);
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      validated: false,
      form: this.getDefaultForm(),
      healthPlans: [],
      plans: [],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
    }
  },
  methods: {
    getDefaultForm() {
      return {
        id: null,
        health_plan: null,
        plan: null,
        registration: null,
        token: null,
        valid_until: null,
        holder: null
      };
    },
    onShow() {
      this.getClinicHealthPlans();
    },
    async getClinicHealthPlans() {
      try {
        const res = await this.api.getAllClinicHealthPlans(this.clinic.id);
        this.healthPlans = res.data.map(clinicHealthPlan => clinicHealthPlan.health_plan);
        this.healthPlans = this.healthPlans.sort((a, b) => a.fantasy_name.localeCompare(b.fantasy_name));
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    async searchPlan(query) {
      const res = await this.api.searchPlan(this.form.health_plan.id, query);
      this.plans = res.data;
    },
    resetModal() {
      this.form = this.getDefaultForm();
      this.validated = false;
    },
    add() {
      if (!this.isValid()) return;
      this.addPersonHealthPlan({
        ...this.form,
        health_plan: this.form.health_plan,
        plan: this.form.plan,
        valid_until: this.form.valid_until ? moment(this.form.valid_until, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
      });
    },
    editPlan() {
      if (!this.isValid()) return
      this.editPersonHealthPlan({
        ...this.form,
        health_plan: this.form.health_plan,
        plan: this.form.plan,
        valid_until: this.form.valid_until ? moment(this.form.valid_until, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
      }, this.editIndex)
    },
    isValid() {
      this.validated = true;
      return Boolean(this.form.health_plan &&
        (this.form.health_plan.type !== 'private_health' || this.form.registration) &&
        (this.form.health_plan.type  !== 'private_health' || this.form.valid_until));
    },
    disabledDates(date) {
      return date < new Date()
    },
  },
  watch: {
    personHealthPlan(value) {
      this.healthPlans = [value.health_plan];
      this.form = {
        ...value,
        valid_until: value.valid_until ? moment(value.valid_until).toDate() : null,
      }
    }
  }
}
</script>
<style lang="scss">
#new-medical-insurance-modal {
  font-family: var(--font-default);
  font-style: normal;
  font-weight: 600;

  .help {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: var(--type-placeholder);
  }

    .header {
      border-bottom: 1px solid var(--neutral-200);
      padding: 1rem;


      .title {
        font-family: var(--font-default);
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
        margin-bottom: 0;
      }

      #close {
        width: 24px;
        height: 24px;
        fill: var(--neutral-500);
        cursor: pointer;
      }
    }

    .body {
      padding: 24px 24px 0 24px;
    }

    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      max-width: 536px;
      padding: 0 0 1rem;
    }
}
</style>
